
import { userCosntants } from '../../redux/constants/user.constants';
import { persistor } from '../../redux/store';
// import { userConstants } from '../constants/user.constants';


export const logout = () => (dispatch) => {
  dispatch({ type: userCosntants.LOGOUT }); // Dispatch LOGOUT action
  localStorage.removeItem("user"); // Clear localStorage
  persistor.purge(); // Clear persisted state if using Redux Persist
};

