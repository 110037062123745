import React, { useState } from "react";

import { Layout, Menu } from "antd/lib";
import { UserOutlined } from "@ant-design/icons";
import { RiLogoutCircleLine } from "react-icons/ri";
import { FaBlog } from "react-icons/fa";
import { Link } from "react-router-dom";
import AlertDisplay from "../controls/AlertDisplay/AlertDisplay";
import Spinner from "../controls/Spinner/Spinner";
import { logout } from "../pages/logout/logout";
import { useDispatch } from "react-redux";
// import "./../global.css";

const { Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const MainLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useDispatch();

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const handleLogout = () => {
    dispatch(logout()); // Dispatch the logout action
    window.location.href = "/login"; // Redirect to login page
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider breakpoint="lg" collapsedWidth="0">
        <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
          {/* {
              menuData.map((data,index)=>{
                return (<SubMenu key={index} icon={<UserOutlined />} title={data.name}>
                    {
                      data.menuItems.map((menuItem,index)=>{
                        return( <Menu.Item key={"menuItem"+index} url={menuItem.url}> <Link key={"link"+index} to={menuItem.url}/> {menuItem.name} </Menu.Item>)
                      })
                    }
                </SubMenu>)
              })
            } */}
          <SubMenu key="1" icon={<UserOutlined />} title="Admin">
            <Menu.Item key="1">
              <Link to="/table/c03b8993-c4df-4349-ab75-66fa6d17c6ab/list/3b8ff057-ffc8-460d-b87f-c3bd9d6b8c77" />{" "}
              Tables{" "}
            </Menu.Item>
            <Menu.Item key="2">
              <Link
                to="/table/32630e4e-881e-496c-8b0f-e231db49b3e7/list/d56ae604-71f4-4e7f-9fdb-0fa5e1c4ee0d"
                title="Table Relationship"
              />{" "}
              Tables Relations{" "}
            </Menu.Item>
            <Menu.Item key="3">
              <Link
                to="/table/b0ae819b-1696-4938-8808-9e6663905094/list/b089fa34-db55-49e4-872b-f991b5b1c756"
                title="Table"
              />{" "}
              Controls{" "}
            </Menu.Item>
            <Menu.Item key="4">
              <Link
                to="/table/8216d804-42f9-464d-93c1-4d129c5eace1/list/3135217c-dce8-4e7c-a242-a4282de02f93"
                title="Columns"
              />{" "}
              Table Columns{" "}
            </Menu.Item>
            <Menu.Item key="5">
              <Link
                to="/table/75f46caf-f3ad-4f3e-be68-3ceffed2f584/list/a5e5e016-e1d0-4eb1-85ed-7355acbb2e6d"
                title="Views"
              />{" "}
              Views{" "}
            </Menu.Item>
            <Menu.Item key="6">
              <Link
                to="/table/974f1f85-ed2c-405e-8613-4e2c54bbcb8c/list/e05447c7-9524-4c4c-84ef-c2bd4cff2665"
                title="Table Column Mapping for Data Transfer"
              />{" "}
              Table Column Mapping for Data Transfer{" "}
            </Menu.Item>
            <Menu.Item key="7">
              <Link
                to="/table/525a1aa8-a40b-4577-bd5e-6117db69559e/list/14663ba9-ac92-42c5-8cd6-f4272b4a3bd9"
                title="Product Generator for Marketplace"
              />{" "}
              Generate Products{" "}
            </Menu.Item>
            <Menu.Item key="8">
              <Link
                to="/table/c03b8993-c4df-4349-ab75-66fa6d17c6ab/edit/8cbfc1ee-0c8f-40fb-9d4f-c4f60380cf1e"
                title="Template"
              />{" "}
              Template{" "}
            </Menu.Item>
          </SubMenu>
          <SubMenu key="100" icon={<UserOutlined />} title="Catch Experts">
            <Menu.Item key="101">
              <Link to="/table/ae9d80c8-f2dc-4096-80cd-71281dd1e3f6/list/9d21034a-40d2-4f6f-95e8-525f9f910221" />{" "}
              Page SEO
            </Menu.Item>
            <Menu.Item key="102">
              <Link
                to="/table/a30a60be-7a31-4d74-afcb-da0149ff202e/list/c4b0865d-082b-4123-8107-e3e8fd5d7b1a"
                title="Page Details"
              />
              Page Details
            </Menu.Item>
            <Menu.Item key="106">
              <Link
                to="/v1/table/5cd448c5-c790-41dc-8e8c-1e3c5dd02269/list/dbfcaf87-8e58-4b11-9e66-cd05798402c5"
                title="Countries"
              />
              Countries
            </Menu.Item>
            <Menu.Item key="107">
              <Link
                to="/v1/table/c779f0d1-ff30-4d03-8a28-0c8e32487200/list/257269c5-a838-4a0e-829e-174a4a5dddca"
                title="States"
              />
              States
            </Menu.Item>
            <Menu.Item key="108">
              <Link
                to="/v1/table/f737440b-4e90-4abc-be58-a938bc9c5aee/list/ba7a44ff-edb4-4a3b-8b2a-b3417da8e204"
                title="Cities"
              />
              Cities
            </Menu.Item>
            <Menu.Item key="111" title="Categories">
              <Link to="/v1/table/d6b8de77-dd23-4321-870b-9da9fc723db8/list/7dbab521-9d28-4317-b60c-a74755f1bdf7" title="Categories" />
              Categories
            </Menu.Item>
            <Menu.Item key="110">
              <Link to="/map-locations" title="Map Locations" />
              Map Locations
            </Menu.Item>
            <Menu.Item key="105">
              <Link to="/import-entities" title="import Entities" />
              Import Entities
            </Menu.Item>
            <Menu.Item key="109">
              <Link to="/download-entities-details" title="Download Entities" />
              Download Entities Details
            </Menu.Item>
            <Menu.Item key="103">
              <Link
                to="/table/85d5ffd0-33c4-4fa6-87c7-bcd7bbf5a2d8/list/5aab9b64-ca8f-4cc6-b299-52becfd0b404"
                title="FAQ Summary"
              />
              FAQ Summary
            </Menu.Item>
            <Menu.Item key="104">
              <Link
                to="/table/27242c8e-8679-4acc-95f8-d600ca6113e0/list/ed723400-5848-4a51-b988-fb3861805a01"
                title="Columns"
              />{" "}
              FAQ Q&A
            </Menu.Item>
          </SubMenu>

          <SubMenu key="200" icon={<FaBlog />} title="Blogs">
            <Menu.Item key="201">
              <Link to="/create-blog" title="Create Blog" />
              Create Blog
            </Menu.Item>
            <Menu.Item key="202">
              <Link
                to="/table/932fbff1-2436-4f74-b838-e5cf317b735f/list/27a55e1d-13ff-4d5b-8f6e-23d99d93d377"
                title="Blogs"
              />
              Blogs
            </Menu.Item>
            <Menu.Item key={203}>
              <Link
                to="/blogs/3e428464-c8c4-4337-9f08-0f0e3562c0f0"
                title="Blog List"
              />
              Blog List
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="400">
            <div className="flex gap-2 items-center">
              <RiLogoutCircleLine />
              <button onClick={handleLogout}>Logout</button>
            </div>
          </Menu.Item>
          {/* <SubMenu key="200" icon={<UserOutlined />} title="Images">
            
              <Menu.Item key="210">
                <Link
                  to="/table/0fd90c55-fbed-4271-b7f9-6f1a8a56c84e/list/26edef44-2976-4273-89d1-ee27ffc556db"
                  title="Images"
                />{" "}
                Images{" "}
              </Menu.Item>
              <Menu.Item key="211">
                <Link
                  to="/table/e53a77bf-e03e-46e6-8b7d-d77cd6499d45/list/7407aafc-f353-45fa-9e45-17e564f7f46d"
                  title="Groups"
                />{" "}
                Image Groups{" "}
              </Menu.Item>
            </SubMenu> */}
          {/* <SubMenu key="2" icon={<UserOutlined />} title="Marketplace">
              <Menu.Item key="2.1">
                <Link
                  to="/table/c03b8993-c4df-4349-ab75-66fa6d17c6ab/edit/475c3e9c-ddae-432c-b409-e6c545cae400"
                  title="Marketplace Products"
                />{" "}
                Products{" "}
              </Menu.Item>
            </SubMenu> */}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <AlertDisplay />
        <Spinner>
          <Content style={{ margin: "5px" }}>{children}</Content>
        </Spinner>
        <Footer style={{ textAlign: "center" }}>Fusion @2021</Footer>
      </Layout>
    </Layout>
  );
};

export default MainLayout;

// import React from 'react';
// import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';

// import { Layout, Menu, Breadcrumb } from 'antd/lib';
// import {
//   DesktopOutlined,
//   PieChartOutlined,
//   FileOutlined,
//   TeamOutlined,
//   UserOutlined,
// } from '@ant-design/icons';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

// import './MainLayout.less';

// const { SubMenu } = Menu;
// const { Header, Content, Sider } = Layout;

// const rootRoutes = ['/', '/about', '/contact'];
// const aboutSubRoutes = ['/about/me', '/about/company'];

// export default class MainLayout extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       collapsed: false,
//     };
//   }

//   onCollapse = (collapsed) => {
//     this.setState({ collapsed });
//   };

//   render() {
//     const { collapsed } = this.state;
//     const { children } = this.props;
//     return (
// <Layout>
//     <Header className="header">
//       <div className="logo" />
//       <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']}>
//         <Menu.Item key="1">nav 1</Menu.Item>
//         <Menu.Item key="2">nav 2</Menu.Item>
//         <Menu.Item key="3">nav 3</Menu.Item>
//       </Menu>
//     </Header>
//     <Layout>
//       <Sider breakpoint="lg" collapsible collapsed={collapsed} onCollapse={this.onCollapse} width={200} >
//         <Menu
//           mode="inline"
//           defaultSelectedKeys={[
//             aboutSubRoutes.includes(window.location.pathname)
//               ? '1'
//               : rootRoutes.indexOf(window.location.pathname).toString(),
//           ]}          defaultOpenKeys={['sub1']}
//           style={{ height: '100%', borderRight: 0 }}
//         >
//           <SubMenu key="sub1" icon={<MenuUnfoldOutlined />} title="Pages">
//             <Menu.Item key="0" style={{ margin: '4px 0px 0px 2px', minHeight: 10 }}>
//               <Link to="/">
//                 <RightOutlined />
//                 <span className="menu-item-link">Home</span>
//               </Link>
//             </Menu.Item>
//             <Menu.Item key="1" style={{ margin: '4px 0px 0px 2px', minHeight: 10 }}>
//               <Link to="/about">
//               <RightOutlined />
//                 <span className="menu-item-link">About</span>
//               </Link>
//             </Menu.Item>
//             <Menu.Item key="2" style={{ margin: '4px 0px 0px 2px', minHeight: 10 }}>
//               <Link to="/contact">
//               <RightOutlined />
//                 <span className="menu-item-link">Contact</span>
//               </Link>
//             </Menu.Item>

//           </SubMenu>
//         </Menu>
//       </Sider>
//       <Layout style={{ padding: '0 1px 1px' }}>
//       </Layout>
//     </Layout>
//   </Layout>
//       );
//   }
// }

// MainLayout.propTypes = {
//   children: PropTypes.element.isRequired,
// };
