import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import MainLayout from "./layout/MainLayout";
import RouteWithSubRoutes from "./RouteWithSubRoutes";

import PageLayout from "./layout/PageLayout";
import { useSelector } from "react-redux";

const Home = lazy(() => import("pages/home/Home"));
const About = lazy(() => import("pages/about/About"));
const Contact = lazy(() => import("pages/contact/Contact"));
const Login = lazy(() => import("pages/login/loginController"));
const List = lazy(() => import("pages/list/listGrid"));
const FNList = lazy(() => import("pages/fnlistsample/fnListSample"));
const FNTableList = lazy(() => import("pages/fntable/list/fnTableList"));
const FNTableFilter = lazy(() => import("pages/fntable/filter/FNTableFilter"));
const FNTableResize = lazy(() => import("pages/fntable/list/fnTableResize"));
const FNTableEdit = lazy(() => import("pages/fntable/edit/fnTableEdit"));
const FNTableInsertRow = lazy(() => import("pages/fntable/new/fnTableNew"));
const FNViewInsert = lazy(() => import("pages/fnview/new/fnViewNew"));
const ImportPage = lazy(
  () => import("./pages/catchexperts/importEntities/ImportPage"),
);
const DownloadDetails = lazy(
  () =>
    import(
      "./pages/catchexperts/download_entities_details/DownloadDetailsView"
    ),
);
const LocationsPage = lazy(
  () => import("./pages/catchexperts/map_locations/LocationsPage"),
);

const CreateBlogpage = lazy(
  () => import("./pages/catchexperts/blogs/CreateBlogPage"),
);

const BlogList = lazy(() => import("./pages/catchexperts/blogs/BlogList"));

const UpdateBlog = lazy(() => import("./pages/catchexperts/blogs/UpdateBlog"));

const BloglistPage = lazy(() => import("./pages/catchexperts/blogs/BloglistPage"));


const testPage = lazy(
  () => import("./pages/catchexperts/fntablev1/listv1/fnTableListViewV1"),
);

const FNTableListV1 = lazy(
  () => import("./pages/catchexperts/fntablev1/listv1/fnTableListV1"),
);


const PrivateRoutes = [
  {
    path: "/",
    component: Home,
  },
  { path: "/test", component: testPage },

  {
    path: "/import-entities",
    component: ImportPage,
  },
  { path: "/download-entities-details", component: DownloadDetails },
  {
    path: "/map-locations",
    component: LocationsPage,
  },
  { path: "/create-blog", component: CreateBlogpage },
  { path: "/blog-list", component: BlogList },
  { path: "/blog/:viewId/:id/edit/:is_editing", component: UpdateBlog },
  { path: "/blogs/:viewId", component: BloglistPage },
  {
    path: "/about",
    component: About,
  },
  {
    path: "/list",
    component: FNList,
  },

  {
    path: "/v1/table/:tableId/list/:viewId",
    component: FNTableListV1,
  },

  {
    path: "/table/:tableId/list/:viewId",
    component: FNTableList,
  },
  {
    path: "/table/:tableId/:viewId/filter",
    component: FNTableFilter,
  },
  {
    path: "/table/resizetable",
    component: FNTableResize,
  },
  {
    path: "/table/:tableId/edit/:rowId",
    component: FNTableEdit,
  },
  {
    path: "/table/:tableId/insert",
    component: FNTableInsertRow,
  },
  {
    path: "/view_columns/:viewId/edit",
    component: FNViewInsert,
  },
];

const PublicRoutes = [
  {
    path: "/contact",
    component: Contact,
  },
  {
    path: "/login",
    component: Login,
  },
];

const PublicLayoutRoutes = {
  layout: PageLayout,
  paths: PublicRoutes,
};

const PrivateLayoutRoutes = {
  layout: MainLayout,
  paths: PrivateRoutes,
};

// const SetPrivateRoute = (layoutPaths) => {
//   const Layout = layoutPaths.layout;
//   const paths = layoutPaths.paths.map((x) => x.path);
//   console.log(paths);
//   const LayoutComponent = (
//     <Layout
//       children={layoutPaths.paths.map((x) => (
//         <Route key={x.path} {...x} />
//       ))}
//     />
//   );
//   return <Route exact path={paths} children={LayoutComponent} />;
// };

const SetPublicRoute = (layoutPaths) => {
  const Layout = layoutPaths.layout;
  const paths = layoutPaths.paths.map((x) => x.path);
  const LayoutComponent = (
    <Layout
      children={layoutPaths.paths.map((x) => (
        <Route key={x.path} {...x} />
      ))}
    />
  );
  return <Route exact path={paths} children={LayoutComponent} />;
};

const PrivateRoute = ({ children }) => {
  const isLoggedIn = useSelector((state) => state.authentication?.loggedIn);

  // Redirect to login page if user is not logged in
  return isLoggedIn ? children : <Navigate to="/login" />;
};

const AppRouterV1 = () => {
  return (
    <Router>
      <Routes>
        {PublicRoutes.map((route, i) => (
          <Route
            key={i}
            path={route.path}
            element={
              <React.Suspense fallback={<div>Loading...</div>}>
                <PageLayout>
                  <route.component />
                </PageLayout>
              </React.Suspense>
            }
          />
        ))}
        {PrivateRoutes.map((route, i) => (
          <Route
            key={i}
            path={route.path}
            element={
              <React.Suspense fallback={<div>Loading...</div>}>
                <PrivateRoute>
                  <MainLayout>
                    <route.component />
                  </MainLayout>
                </PrivateRoute>
              </React.Suspense>
            }
          />
        ))}
      </Routes>
    </Router>
  );
};

// const AppRouterV1 = () => {
//   return (
//     <Router>
//     <BrowserRouter>
//       <Suspense fallback={<div className="lazy-loading">Loading...</div>}>
//         <SetPrivateRoute
//           layout={PrivateLayoutRoutes.layout}
//           paths={PrivateLayoutRoutes.paths}
//         />
//       </Suspense>

//       <Suspense fallback={<div className="lazy-loading">Loading...</div>}>
//         <SetPublicRoute
//           layout={PublicLayoutRoutes.layout}
//           paths={PublicLayoutRoutes.paths}
//         />
//       </Suspense>
//     </BrowserRouter>
//     </Router>
//   );
// };

export default AppRouterV1;
