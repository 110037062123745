import "./global.css";

import React from "react";
import AppRouterV1 from "./routesV1";

const App = () => {
  return <AppRouterV1 />;
};

export default App;
