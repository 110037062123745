// // src/redux/store.js

// import { configureStore } from '@reduxjs/toolkit';
// import rootReducer from './reducers';
// import thunkMiddleware from 'redux-thunk';
// import { createLogger } from 'redux-logger';
// import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';

// // Configure logger middleware
// const loggerMiddleware = createLogger();

// // Define the store configuration
// const store = configureStore({
//   reducer: rootReducer, // The root reducer object
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       // Middleware configuration
//       immutableCheck: {
//         // Configure immutable state invariant
//         warnAfter: 64, // Warn after a specific number of keys
//       },
//     }).concat(thunkMiddleware, loggerMiddleware, reduxImmutableStateInvariant()),
//   devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools in development
//   // Optionally, set preloadedState here
//   // preloadedState: initialState,
// });

// export default store;

import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Default: localStorage for web
import rootReducer from "./reducers";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";

// Configure logger middleware
const loggerMiddleware = createLogger();

// Redux Persist configuration
const persistConfig = {
  key: "root", // Key for the persisted state
  storage, // Storage mechanism (localStorage in this case)
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the Redux store
const store = configureStore({
  reducer: persistedReducer, // Use the persisted reducer here
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: {
        warnAfter: 64, // Warn after a specific number of keys
      },
      serializableCheck: false, // Disable warnings for non-serializable values (e.g., functions)
    }).concat(
      thunkMiddleware,
      loggerMiddleware,
      reduxImmutableStateInvariant(),
    ),
  devTools: process.env.NODE_ENV !== "production", // Enable Redux DevTools in development
});

// Create the persistor
const persistor = persistStore(store);

export  { store, persistor };
export default store;