// import {userCosntants} from '../constants/user.constants';

import { userCosntants } from "../constants/user.constants";

// let user = JSON.parse(localStorage.getItem("user"));
// const initialState = user ? {loggedIn: true,user} : {}

// export default function authentication(state=initialState,action)
// {
//     switch(action.type)
//     {
//         case userCosntants.LOGIN_REQUEST:
//             return {
//                 loggedIn: true,
//                 user : action.user
//             }
//         case userCosntants.LOGIN_SUCCESS:
//             {
//                 return {loggedIn: true,
//                 user : action.data
//                 }            
//             }
//         case userCosntants.LOGIN_FAILURE:
//             {
//                 return {
//                 }
//             }
//         case  userCosntants.LOGOUT:
//             {
//                 return {}
//             }
//         default: 
//             return state;
//     }
// }

// import { userConstants } from '../constants/user.constants';

let user = JSON.parse(localStorage.getItem("user")); // Load user from localStorage
const initialState = user ? { loggedIn: true, user } : {}; // Initial state based on localStorage

export default function authentication(state = initialState, action) {
  switch (action.type) {
    case userCosntants.LOGIN_REQUEST:
      return {
        loggedIn: false, // You can set false during a login request if needed
        user: action.user, // User data during the request (optional)
      };

    case userCosntants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.data, // Update user data from the login response
      };

    case userCosntants.LOGIN_FAILURE:
      return {
        loggedIn: false, // Reset logged-in status on failure
      };

    case userCosntants.LOGOUT:
      localStorage.removeItem("user"); // Clear user data from localStorage
      return {}; // Reset state to an empty object

    default:
      return state; // Default case returns current state
  }
}
